import {useState, useEffect} from 'react';
import apirone from './api';


function useRequest(url, payload, init = false) {
    
    const payloadStr = JSON.stringify(payload);
    //console.log('response.init', payloadStr, init)
    const [response, setResponse] = useState({url: url, payload: payloadStr, loading: init ? false : true, data: init, error: false, stamp: false});
    useEffect(() => {
        //console.log('response.data', response.data)
        if (!response.data || response.url !== url || response.payload !== payloadStr) {
            apirone.query2(response.url, payload).then( r => {
                const stamp = new Date();
                if (r) {
                    setResponse(oldResponse => {
                        return {url: url, payload: payloadStr, loading: false, data: r, error: false, stamp: stamp.getTime()}
                    })
                } else {
                    setResponse({url: url, payload: payloadStr, loading: false, data: [], error: true, stamp: stamp.getTime()})
                }
            })  
        }
    },[url, payloadStr, response.data, response.loading]);
    
    const mutate = () => {
        setResponse({url: url, loading: true, data: false, error: false})
    }
    const softMutate = () => {
        setResponse({url: url, loading: true, data: response.data, error: false})
    }
    return {...response, mutate: mutate, softMutate: softMutate};
}


function usePublicRequest(url, payload, init = false) {
    //console.log('usePublicRequest', url, init);
    const loaded = init && ((init.type && init.type == 'page') || init.compare);
    const payloadStr = JSON.stringify(payload);
    const [response, setResponse] = useState({url: url, payload: payloadStr, loading: init ? false : true, data: init, error: false, loaded: loaded, stamp: false});
    useEffect(() => {
        //console.log('LOAD...' + (response.payload !== payloadStr ? 'Yez!' : 'No!'), response.payload, payloadStr)
        if (!response.loaded || response.url !== url || response.payload !== payloadStr) {
            
            apirone.queryPublic(url, payload).then( r => {
                const stamp = new Date();
                if (r) {
                    setResponse({url: url, payload: payloadStr, loading: false, data: r, error: false, loaded: true, stamp: stamp.getTime()})
                } else {
                    setResponse({url: url, payload: payloadStr, loading: false, data: [], error: true, loaded: true, stamp: stamp.getTime()})
                }
            })  
        }
    },[url, payloadStr, response.loaded]);
    
    const mutate = () => {
        setResponse(oldResponse => {return {...oldResponse, loaded: false, loading: true, url: url, payload: payloadStr}})
    }
    const softMutate = () => {
        setResponse(oldResponse => {return {...oldResponse, loaded: false, error: false, loading: true, url: url, payload: payloadStr}})
        //setResponse({url: url, loading: true, data: response.data, error: false})
    }
    return {...response, mutate: mutate, softMutate: softMutate};
}


export default useRequest;

export {usePublicRequest}
