/*
Один из вариантов вывода изображений, с мета-тегами, ленивой загрузкой, прелоадером, заполнением размытой версией фона и т.п.
*/

import { useState, useEffect, useRef } from 'react';
import { makeSrcSet } from '../../utils/imageFormat';
import { useInView } from 'react-intersection-observer';
//import LazyImage from '../../components/LazyImage/LazyImage';

const ListingBanner = (props) => {
    const bgSrc = props.bgSrc? props.bgSrc : props.src; 
    const width = props.width ? props.width : 1920;
    const height = props.height ? props.height : 800;
    const [state, setState] = useState({loaded: true, src: null, width: width, height: height});
    const { ref, inView, entry } = useInView({
        triggerOnce: true,
        rootMargin: '200px 100px',
    });
    //const imageRef = useRef();
    const viewBox = "0 0 " + state.width + " " + state.height;
    const srcSet = props.srcSet ? makeSrcSet(props.srcSet) : null;
    const wideBlur = props.wideBlur ? props.wideBlur : null;

    //console.log('ListingBanner', state);

    const handleImageLoaded = (i) => {
        //console.log('ListingBanner image loaded', entry.target, props.src);
        const next = entry?.target?.nextSibling;
        const isIMG = (next?.tagName == 'IMG');
        const w = isIMG ? next.width : width;
        const h = isIMG ? next.height : height;
        setState({loaded: true, src: props.src, width: w, height: h});
    }

    const handleImageErrored = () => {
        setState({loaded: false, src: null, width: width, height: height});
    }

    const imgClassName = [props.imgClassName ? props.imgClassName : 'img', state.loaded ? 'loaded' : 'hidden'].join(' ');

    /*useEffect(() => {
        imageRef.current = new Image;
        imageRef.current.onload = handleImageLoaded;
        imageRef.current.onerror = handleImageErrored;
        imageRef.current.src = props.src;
    },[])*/

    //console.log('IMG', wideBlur );

    return(
        <div style={{position: 'relative'}} className={(props.className ? props.className : 'image-box-banner') + (wideBlur ? ' wide-blur-banner' : ' ')}>
        
        {(wideBlur && bgSrc) && 
            <div className="background_blur" style={{backgroundImage: 'URL(' + bgSrc + ')'}}></div>
        }

            <svg ref={ref} width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <use xlinkHref="#img_placeholder" />
            </svg>
            
        {(inView || props.lazy == false) &&
            <img 
                
                itemProp={props.itemProp ? props.itemProp : null} 
                className={imgClassName}
                //ref={imageRef}
                key={props.src}
                //lazy={props.lazy !== undefined ? props.lazy : true}
                width={width}
                height={height}
                //id={}
                alt={props.alt ? props.alt : 'air.one airplane marketplace'} 
                title={props.title ? props.title : 'Airplane marketplace'}  
                onLoad={handleImageLoaded}
                onError={handleImageErrored}
                src={props.src} 
                style={{position:'absolute', top: '50%', left: '50%'}}
                srcSet={props.srcSet ? makeSrcSet(props.srcSet, props.src) : null}
            />
        }

        </div>
    )
}
//<LazyImage />
export default ListingBanner;



/*


    <svg width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <use xlinkHref="#img_placeholder" />
            </svg>

        {(wideBlur && bgSrc) && 
            <div className="background_blur" style={{backgroundImage: 'URL(' + bgSrc + ')'}}></div>
        }
            
        {props.src && (inView || props.lazy == false) &&
            <img 
                ref={ref}
                itemProp={props.itemProp ? props.itemProp : null} 
                className={state.loaded ? 'loaded' : 'hidden'}
                //ref={imageRef}
                key={props.src}
                //lazy={props.lazy !== undefined ? props.lazy : true}
                width={width}
                height={height}
                //id={}
                alt={props.alt ? props.alt : 'air.one airplane marketplace'} 
                title={props.title ? props.title : 'Airplane marketplace'}  
                onLoad={handleImageLoaded}
                onError={handleImageErrored}
                src={props.src} 
                style={{position:'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                srcSet={props.srcSet ? makeSrcSet(props.srcSet, props.src) : null}
            />
        }





*/