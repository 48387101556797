/*
Блок, для отображения загрузки контента или временной заглушки. 
По умолчанию ..., можно указать текст. Рендерится по центру родителя светлым шрифтом.
*/


const Floater = (props) => {
  return (
    <div className="floater">{props.text ? props.text : '...'}</div>
  )
}

export default Floater;
