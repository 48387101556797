
function SVGWave() {
  return (
    <svg version="1.1" viewBox="0 0 3600 360" xmlns="http://www.w3.org/2000/svg">
 <path d="m640.8 242.2c161.2-77.93 248.4-191.5 414-216.4 165.6-24.84 331.2 74.53 496.8 111.8s331.2 12.42 496.8-37.27 331.2-124.2 496.8-86.95c165.6 37.27 319.5 169.9 414 228.8 94.18 58.7 271.7 118.32 640.8 117.82l6.2014 20.311-3615 1.1641 8.7861-21.475c264.5 0 479.6-39.86 640.8-117.82z" fillRule="evenodd"/>
</svg>
  )
}

export default SVGWave
