/*
Карточка новости
*/

import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../components/LazyImage/LazyImage';

function News(props) {
	const {id, url, title, media, text, ...rest} = {...props};

	const navigate = useNavigate();
	const imageTitle = title ? title : 'air.one news';
	const handleClick = (e) => {
		e.preventDefault();
		navigate(url);
	}

	return (
		<article className="news_card bar" id={'news_card' + id} >
			<a href={url} onClick={handleClick}>
				<div className="news_wrapper">
					<div className="news_image">
						<div className="news_image_container">
						{media &&
							<LazyImage src={media.replace(/\.(png|jpg)/,'.webp')} alt={imageTitle} title={imageTitle} />
						}	
						</div>
					</div>
					<div className="news_announce">
						<h3>{title}</h3>
						<div className="article"
					  dangerouslySetInnerHTML={{
					    __html: text
					  }}></div>
					  <div className="readmore">
					  	Read more
					  </div>
				  </div>
				</div>
			</a>
		</article>
	)
}

export default News;

