/*
Кнопка Назад с SVG стрелкой
*/

function BackButton({title, onClick}) {
  return (
    <button className="button_back" onClick={onClick} aria-label={title}>
      <span>&lt;{ title.toLowerCase()}</span>
    </button>
  )
}

export default BackButton;