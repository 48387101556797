/*


<format key="pridea_big">
        <meta>
            <title lang="en">PRidea original</title>
        </meta>
        <scale x="1920" />
    </format>

    <format key="pridea_bigger">
        <meta>
            <title lang="en">PRidea bigger original</title>
        </meta>
        <scale x="2560" />
    </format>


    <format key="pridea_source">
        <meta>
            <title lang="en">PRidea original</title>
        </meta>
        <scale x="300" />
    </format>

    <format key="pridea_source_2x">
        <meta>
            <title lang="en">PRidea original</title>
        </meta>
        <scale x="600" />
    </format>

    <format key="pridea_dealer">
        <meta>
            <title lang="en">PRidea dealer</title>
        </meta>
        <scale x="200" y="100" mode="inset" />
    </format>

    <format key="pridea_article">
        <meta>
            <title lang="en">Article</title>
        </meta>
        <scale x="640" />
    </format>

    <format key="pridea_card">
        <meta>
            <title lang="en">Article</title>
        </meta>
        <scale x="350" />
    </format>

    <format key="pridea_thumb">
        <meta>
            <title lang="en">PRidea thumbnail</title>
        </meta>
        <scale y="300" x="400" mode="outbound"/>
    </format>

    <format key="pridea_thumb_2">
        <meta>
            <title lang="en">PRidea thumbnail 2</title>
        </meta>
        <scale y="270" x="400" mode="outbound"/>
    </format>

    <format key="pridea_view">
        <meta>
            <title lang="en">PRidea view</title>
        </meta>
        <scale y="800" x="1920" mode="outbound"/>
    </format>



    <format key="pridea_view_scale">
        <meta>
            <title lang="en">PRidea scaled view</title>
        </meta>
        <scale x="1176"/>
    </format>
    
    <format key="pridea_icon">
        <meta>
            <title lang="en">PRidea icon</title>
        </meta>
        <scale y="150" x="200" mode="outbound"/>
    </format>

    <format key="pridea_ava">
        <meta>
            <title lang="en">PRidea icon</title>
        </meta>
        <scale y="80" x="80" mode="outbound"/>
    </format>
    
    <format key="pridea_bigava">
        <meta>
            <title lang="en">PRidea icon</title>
        </meta>
        <scale y="300" x="300" mode="outbound"/>
    </format>

    <format key="pridea_showroom_card">
        <meta>
            <title lang="en">PRidea showroom card</title>
        </meta>
        <scale y="135" x="255" mode="outbound"/>
    </format>

    <format key="pridea_showroom_card_2x">
        <meta>
            <title lang="en">PRidea showroom card 2x</title>
        </meta>
        <scale y="270" x="510" mode="outbound"/>
    </format>

    <format key="pridea_showroom_video_mob">
        <meta>
            <title lang="en">PRidea showroom card</title>
        </meta>
        <scale y="300" x="400" mode="inset"/>
    </format>


*/
function imageScale(w, h, ww = false, wh = false) {
	if (!wh && ww) {
		return {width: ww, height: h*(ww/w)}
	}
	return {width: w, height: h}
}

function getImageSize(width, height, format) {
	switch (format) {
		case 'pridea_big': return imageScale(width, height, 1920);
		case 'pridea_bigger': return imageScale(width, height, 2560);
		case 'pridea_source': return imageScale(width, height, 300);
		case 'pridea_source_2x': return imageScale(width, height, 600);
		case 'pridea_article': return imageScale(width, height, 640);
		case 'pridea_card': return imageScale(width, height, 350);
		case 'pridea_card_2x': return imageScale(width, height, 700);
		case 'pridea_view_scale': return imageScale(width, height, 1176);
	}
	return imageScale(width, height);
}

function makeSrcSet(data, src) {
    var srcSet = data; 
    if (data === true && src) {
        if (src.includes('pridea_icon')) {
            srcSet = {
                '100w' : src.replace('pridea_icon', 'pridea_icon_nano'),
                '1000w': src,
            }
        }
        if (src.includes('pridea_view_scale')) {
            srcSet = {
                //'2x': src.replace('pridea_view_scale', 'pridea_view_scale_800'), 
                '400w': src.replace('pridea_view_scale', 'pridea_view_scale_400'), 
                '800w': src.replace('pridea_view_scale', 'pridea_view_scale_800'), 
                '1176w' : src,
            }
        }
        if (src.includes('pridea_source')) {
            srcSet = {
                '1x': src, 
                '2x': src.replace('pridea_source', 'pridea_source_2x'), 
            }
        }
        if (src.includes('pridea_card')) {
            srcSet = {
                '1x': src, 
                '2x': src.replace('pridea_card', 'pridea_card_2x'), 
            }
        }
    }
	return Object.keys(srcSet).map(w => srcSet[w] + ' ' + w).join(', ')
}

export default getImageSize;
export {makeSrcSet};