/*
Страница CMS, выводит заранее продуманные параграфы по известному шаблону.
*/

import React, {useState, useCallback, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import apirone from "../../utils/api";
import getImageSize from "../../utils/imageFormat"
import { useParams } from "react-router-dom";
import appState from '../../stores/appState';
import aiState from '../../stores/aiState';
import Floater from '../../components/Floater/Floater';
import Gallery from '../../components/Gallery/Gallery';
import {usePublicRequest} from '../../utils/useRequest';
import BackButton from '../../components/BackButton/BackButton';
import Meta, {stripTags} from '../../components/Meta/Meta';
import LazyImage from '../../components/LazyImage/LazyImage';
import News from '../../components/News/News';
import Paragraph from './Paragraph';
import {toJS} from 'mobx';




function Page(props) {
    const navigate = useNavigate();
    const { pageKeyParam } = useParams();
    const pageUrl = useLocation();
    const url = apirone.baseURL + pageUrl.pathname;
    const backUrl = props.backUrl ? props.backUrl : false;
    const backTitle = props.backTitle ? props.backTitle : '';
    const pageKey = pageKeyParam ? '/' + pageKeyParam : (props.pageKey ? '/' +props.pageKey : pageUrl.pathname);
    const {loading, data, error} = usePublicRequest( pageKey + '.json', false, appState.cmsData.page);
    useEffect(() => {appState.reset();},[])

    if (loading) return <main className="page"><Floater text={"Loading..."} /></main> 
    if (error || data.content == undefined) return <main className="page"><Floater text={"Error"} /></main> 
    
    let description = null;
    const content = [];
    const paragraphs = data.content.paragraphs && (data.content.paragraphs.map((p, i) => {
        if (!description) {
            if (p.text) {
                description = stripTags(p.text);
                if (description.length > 300) {
                    description = description.substring(0, 300).split(' ');
                    description.pop();
                    description = description.join(' ') + '...';
                } 
            }
        }
        return <Paragraph key={p.type + i} index={i} type={p.type} data={p} />
    }));

    const news = data.content.news && (data.content.news.map((n, i) => {
        const media = n.content?.media?.formatUri ? n.content.media.formatUri.replace('{format}', 'pridea_thumb') : null
        return <News key={n.id} id={n.id} url={apirone.baseURL + n.content.url} title={n.content.title} media={media} text={n.content.teaser}/>
    }));

    if (paragraphs) content.push(paragraphs);
    if (news) content.push(<div className="news_container container">{news}</div>);
        
    const backgroundImage = data.content && data.content.bg && data.content.bg.formatUri ? data.content.bg.formatUri.replace('{format}','pridea_big') : false;
    const style = backgroundImage ? {backgroundImage: 'url(' + apirone.baseURL + backgroundImage + ')'} : null;
    const title = data.content && data.content.title ? data.content.title : 'air.one page';
    const seo = data?.extension?.seo ? data?.extension.seo : null;
    const teaser = data.content?.teaser ? stripTags(data.content.teaser) : description;
    const media = data.content?.media?.formatUri ? data.content.media.formatUri.replace('{format}', 'pridea_view_scale') : null;
    

    aiState.setContext('');

    return(
        <main className={"page page_page page_" + pageKey.replace(/\//g,'_') + (data?.content?.noh1 ? " no-header" : " has-header")} style={style}>
            <Meta 
                title={title + ' | AIR.ONE'}
                description={teaser}
                image={media}
                url={url}
                seo={seo}
            />
            <article itemScope itemType="https://schema.org/Article">
              {
              !data?.content?.noh1 &&
                <header className="container">
                    <div className="header_line">
                        {backUrl &&
                        <div className="back">
                            <BackButton title={backTitle} onClick={()=> {navigate(-1/*backUrl*/)}} />
                        </div>
                        }
                        
                        <h1 itemProp="name">{title}</h1>
                        
                    </div>
                </header>
                }
                {
                    content
                }
            </article>
        </main>
    )
}

export default Page;
