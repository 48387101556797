/*
Картинка с ленивлй загрузкой
*/

import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

function LazyImage({srcSet, itemProp, lazy, onLoad, onError, className, placeholderSrc, src, ...props}) {
	const { ref, inView, entry } = useInView({
		triggerOnce: true,
    rootMargin: '200px 100px',
  });
  const isLazy = lazy !== undefined ? lazy : true;
  const width = props.width ? props.width : 1920;
  const height = props.height ? props.height : 676;
  const style = {width: width + 'px', height: height + 'px'};
  const viewBox = "0 0 " + width + ' ' + height;
  const classNameDef = className ? className : 'lazy_image';
  //console.log(htmlProps);

  const placeholder = props.placeholder ? props.placeholder : <svg viewBox={viewBox} className="image-placeholder-span" ref={ref} {...props} ><use xlinkHref="#img_placeholder" /></svg>

  const handleImageLoaded = (e) => {
  	if (onLoad) {
  		onLoad(ref.current.width, ref.current.height);
  	}
  }

  const handleImageError = (e) => {
  	if (onError) {
  		onError()
  	}
  }

  const usedSrc = src ? src : placeholderSrc;

  useEffect(() => {
      ref.current = new Image;
      ref.current.onload = handleImageLoaded;
      ref.current.onerror = handleImageError;
      ref.current.src = usedSrc;
  }, []);

  return (inView || isLazy == false) && src
  	?
  	<img className={classNameDef + (isLazy ? ' lazy' : ' zealous')} itemProp={itemProp ? itemProp : null} src={usedSrc} srcSet={srcSet? srcSet : null} ref={ref} {...props} />
  	:
    <>
    {
      placeholderSrc
      ? 
      <img className={classNameDef} itemProp={itemProp ? itemProp : null} ref={ref} {...props} src={placeholderSrc} />
      :
      <svg viewBox={viewBox} className="image-placeholder-span" ref={ref} {...props} ><use xlinkHref="#img_placeholder" /></svg>
    }
    </>
  	
}

export default LazyImage;