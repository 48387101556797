/*
Параграфы разных типов
TODO: раскидать по файлам?
*/

import { useNavigate, useLocation } from 'react-router-dom';
import apirone from "../../utils/api";
import getImageSize from "../../utils/imageFormat"
import Gallery from '../../components/Gallery/Gallery';
import LazyImage from '../../components/LazyImage/LazyImage';
import News from '../../components/News/News';
import VideoSlider from "../Page/Paragraphs/VideoSlider/VideoSlider";
import SVGWave from './Paragraphs/SVGWave/SVGWave';
import A1Button from '../../components/A1Button/A1Button';
//import {toJS} from 'mobx';



function NewsParagraph({data}) {
    return (
        <>
            {
            data.title && 
                (data.h1 
                ?   
                <h1>{data.title}</h1> 
                : 
                <h2>{data.title}</h2> 
                ) 
            }
            <div className={"news " + data.black ? 'news_black' : 'news_white'} >
                {
                    data.news.map(n => {
                        const media = n?.content?.media ?  apirone.baseURL + n.content.media.formatUri.replace('{format}','pridea_thumb') : null;
                        const teaser = n.content.teaser ? n.content.teaser : 'News, Updates and Comparisons';
                        const newsUrl = n.content.comparison && n.content.comparison !== '' ? '/compare/' + n.content.comparison : n.content.url;
                        //console.log('urrl', newsUrl, toJS(n.content));
                        return <News key={n.id} id={n.id} title={n.content.title} url={newsUrl} media={media} text={teaser} />
                    })
                }
            </div>
        </>
    )
}

function TextParagraph({data}) {
  
    return (
        <>
            {
            data.title && 
                (data.h1 
                    ?   
                    <h1>{data.title}</h1> 
                    : 
                    <h2>{data.title}</h2> 
                ) 
            }
            <div className="text" dangerouslySetInnerHTML={{__html: data.text}}></div>
            {data.url && <div className="call_to_action" ><a href={data.url}>{data.link_text ? data.link_text : data.url}</a></div>}
        </>
    );
}

function GalleryParagraph({data}) {
    return (
        <>
            {
            data.title && 
                (data.h1 
                    ?   
                    <h1>{data.title}</h1> 
                    : 
                    <h2>{data.title}</h2> 
                ) 
            }
            <div className={"gallery " + data.black ? 'gallery_black' : 'gallery_white'} >
                <Gallery infinite={true} noIcons={data.wide ? data.wide : false} title={data.title} prefix={apirone.baseURL} items={data.background ? data.background : null}  />
            </div>
        </>
    );
}


function PCard({paragraphIndex, data}) {
    const src = apirone.baseURL + data.image.formatUri.replace('{format}', 'pridea_card').replace(/\.(png|jpg|jpeg)/,'.webp');
    const src2x = apirone.baseURL + data.image.formatUri.replace('{format}', 'pridea_card_2x').replace(/\.(png|jpg|jpeg)/,'.webp');
    const imageSize = getImageSize(data.image?.properties?.width, data.image?.properties?.height, 'pridea_article');
    const title = data.image.title ? data.image.title : data.title;
    const alt = data.image.description ? data.image.description : data.title;

    return (
        <div className="card">
            <LazyImage 
                lazy={paragraphIndex > 0}
                width={imageSize.width} 
                height={imageSize.height} 
                src={src} 
                srcSet={src + ' 1x, ' + src2x + ' 2x'}
                alt={alt} 
                //title={title} 
            />
            {data.title && 
                (data.h1 
                ?   
                <h1>{data.title}</h1> 
                : 
                <h3>{data.title}</h3> 
                )
            }
            {data.text && <div className="text" dangerouslySetInnerHTML={{__html: data.text}}></div>}
            {data.url && <div className="call_to_action" ><a href={data.url}>{data.link_text ? data.link_text : data.url}</a></div>}
        </div>
    )
}

function CardParagraph({index, data}) {
    const style = data.bg ? {backgroundImage: apirone.baseURL + data.bg} : null;
    const cards = data.cards.map((c,i) => <PCard paragraphIndex={index} key={c.title + i} data={c} />)
    return (
        <>
            {
            data.title && 
                (data.h1 
                ?   
                <h1>{data.title}</h1> 
                : 
                <h2>{data.title}</h2> 
                )
            }
            <div className={"cards " + (data.black ? 'gallery_black' : 'gallery_white')} style={style}>
                {cards}
            </div>
        </>
    );
}

function BannerParagraph({index, data}) {
    const imageFormat = data.full ? 'pridea_big' : 'pridea_view_scale';
    const src = apirone.baseURL + data.image.formatUri.replace('{format}', imageFormat).replace(/\.(png|jpg|jpeg)/,'.webp');
    const imageSize = getImageSize(data.image?.properties?.width, data.image?.properties?.height, imageFormat);
    const title = data.image.title ? data.image.title : data.title;
    const alt = data.image.description ? data.image.description : data.title;
    const className = 
        "image_wrapper " + data.align + 
        (data.black ? ' image_black' : ' image_white') + 
        (data.wide ? ' image_wide' : ' image_normal');

    return (
        <div className={className}>
            <div className="image">
                <LazyImage 
                    lazy={index > 0}
                    width={imageSize.width} 
                    height={imageSize.height} 
                    src={src}
                    alt={alt} 
                    title={title} 
                />
            </div>
        </div>
    );
}


function ImageParagraph({index, data}) {
    const src = apirone.baseURL + data.image.formatUri.replace('{format}', 'pridea_source_2x').replace(/\.(png|jpg|jpeg)/,'.webp');
    const src2x = apirone.baseURL + data.image.formatUri.replace('{format}', 'pridea_view_scale').replace(/\.(png|jpg|jpeg)/,'.webp');
    const imageSize = getImageSize(data.image?.properties?.width, data.image?.properties?.height, 'pridea_source_2x');
    const title = data.image.title ? data.image.title : data.title;
    const alt = data.image.description ? data.image.description : data.title;
    //const classWave = data.wave && 'wave_svg';
    const className = 
        "image_wrapper " + data.align + 
        (data.black ? ' image_black' : ' image_white') + 
        (data.wide ? ' image_wide' : ' image_normal');
    return (
        <div className={className}>
            <div className="image">
                <LazyImage 
                    lazy={index > 0}
                    width={imageSize.width} 
                    height={imageSize.height} 
                    src={src}
                    srcSet={src + ' 1x, ' + src2x + ' 2x'} 
                    alt={alt} 
                    title={title} 
                />
            </div>
            <div className="text_wrapper">
                {
                  data.title && 
                    (data.h1 
                        ?   
                        <h1>{data.title}</h1> 
                        : 
                        <h2>{data.title}</h2> 
                    ) 
                }
                <div className="text" dangerouslySetInnerHTML={{__html: data.text}}></div>
                {data.url && 
                    <div className="call_to_action" >
                        {data.linkasbutton 
                            ?
                            <A1Button href={data.url}>{data.link_text ? data.link_text : data.url}</A1Button>
                            :
                            <a  className='link'
                                href={data.url}>{data.link_text ? data.link_text : data.url}
                            </a>
                        }
                    </div>
                }
            </div>

        </div>
    );
}


function Paragraph(props) {
    const className = 
        "paragraph paragraph_" + props.data.type +
        (props.data.black ? ' paragraph_black' : ' paragraph_white') + 
        (props.data.wide ? ' paragraph_wide' : ' paragraph_normal') +
        (props.data.wave ? ' paragraph_wave' : '');


    const id = props.index ? 'paragraph_' + props.index : null;
    const backgroundImage = props.data && props.data.bg && props.data.bg.url ? props.data.bg.url : false;
    const style = backgroundImage ? {backgroundImage: 'url(' + apirone.baseURL + backgroundImage + ')'} : null;
    const containerClass = props.data.full ? 'wide_container' : 'container';
    return (
        <div id={id} className={className} style={style}>
            {props.data.wave && <div className="wave" ><SVGWave /></div>}
            <div className={containerClass}>
            {
                props.type == 'text' && <TextParagraph index={props.index} data={props.data} />
            }
            {
                props.type == 'gallery' && <GalleryParagraph index={props.index} data={props.data} />
            }
            {
                props.type == 'card' && <CardParagraph index={props.index} data={props.data} />
            }
            {
                props.type == 'image' && <ImageParagraph index={props.index} data={props.data} />
            }
            {
                props.type == 'wide_image' && <BannerParagraph index={props.index} data={props.data} />
            }
            {
                props.type == 'news' && <NewsParagraph index={props.index} data={props.data} />
            }
            {
                props.type == 'videoslider' && <VideoSlider index={props.index} data={props.data} />
            }
            
            </div>
           
        </div>
    )
}


export default Paragraph;
