import { useNavigate } from "react-router-dom";
import "./VideoSliderCard.scss";
import A1Button from '../../../../components/A1Button/A1Button';

function VideoSliderCard({
  title,
  text,
  button_url,
  button_title,
  video,
  paragraphIndex,
  index,
  handleVideoEnd,
  h1
}) {

  return (
    <div className="video_container">
      <div className="video_text_bar">
        {
          title &&
          (h1 
            ?   
            <h1 className="video_title">{title}</h1> 
            : 
            <h3 className="video_title">{title}</h3> 
          )
        }
        <div
          className="video_text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
        {(button_title || button_url) && (
          <div className="call_to_action">
            <A1Button className="btn" href={button_url}>
              {button_title}
            </A1Button>
          </div>
        )}
      </div>
      <div className="video_bar">
          <svg className="videoSlider_bg" preserveAspectRatio="none" viewBox="0 0 505 321">
            <use xlinkHref="#videoSlider_bg" />
          </svg>
       <div className="videoSlider_wrapper">
        <div className="videoSlider_videoWrapper">
         <svg width="508" height="286"><use xlinkHref={"#i_placeholder"} /></svg>
        <video
          onEnded={handleVideoEnd}
          className={`sliderVideo_${paragraphIndex}_${index}`}
          playsInline
          muted
        >
          <source src={video.url} type={video.mimeType} />
        </video>
        </div>
        </div>
      </div>
    </div>
  );
}

export default VideoSliderCard;
