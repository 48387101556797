import Slider from "react-slick";
import VideoSliderCard from "../VideoSliderCard/VideoSliderCard";
import { useState, useRef } from "react";

function VideoSlider({ index, data }) {
  const sliderRef = useRef();
  const [noNextVideo, setNoNextVideo] = useState(false);

  const handleSlideChange = (slideIndex) => {
    //const currentVideo = document.querySelector(`.slick-current #sliderVideo_${index}_${slideIndex}`);
    const currentVideo = document.querySelector(
      `.slick-current .sliderVideo_${index}_${slideIndex}`
    );
    //console.log(currentVideo);
    currentVideo.currentTime = 0;
    currentVideo.play();
    //console.log("handleSlideChange", slideIndex);
  };

  const handeBeforeSlideChange = (oldIndex, newIndex) => {
    //console.log("handeBeforeSlideChange", oldIndex, newIndex);
    const currentVideo = document.querySelector(
      `.slick-current .sliderVideo_${index}_${oldIndex}`
    );
    currentVideo.pause();
    currentVideo.currentTime = 0;
  };

  const handleInit = () => {
    const currentVideo = document.querySelector(
      `.slick-current .sliderVideo_${index}_0`
    );
    currentVideo.play();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
    setNoNextVideo(false);
  };

  const handleVideoEnd = (event) => {
    if (!noNextVideo) {
      setNoNextVideo(true);
      setTimeout(() => {
        nextSlide();
      }, 1000);
    } else {
      //console.log("handleVideoEnd", "blocked!");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: handeBeforeSlideChange,
    afterChange: handleSlideChange,
    onInit: handleInit,
    arrows: false,
    fade: true,
    //infinite: false,
    //beforeChange: ( next) => setVideoSlide(next)
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef}>
        {data.slide.map((s, i) => (
          <VideoSliderCard
            paragraphIndex={index}
            index={i}
            key={i}
            {...s}
            handleVideoEnd={handleVideoEnd}
          />
        ))}
      </Slider>
      <svg
        width="1"
        height="1"
        className="hidden"
        xmlns="http://www.w3.org/2000/svg"
      >
        <symbol id="videoSlider_bg" viewBox="0 0 505 321">
          <g fill="#63af13">
            <circle cx="487.22" cy="113.17" r="1.8351" />
            <circle cx="487.22" cy="97.261" r="1.8351" />
            <circle cx="487.22" cy="81.356" r="1.8351" />
            <circle cx="487.22" cy="65.452" r="1.8351" />
            <circle cx="503.12" cy="113.17" r="1.8351" />
            <circle cx="503.12" cy="97.261" r="1.8351" />
            <circle cx="503.12" cy="81.356" r="1.8351" />
            <circle cx="503.12" cy="65.452" r="1.8351" />
            <circle cx="471.31" cy="17.739" r="1.8351" />
            <circle cx="471.31" cy="1.8351" r="1.8351" />
            <circle cx="455.41" cy="17.739" r="1.8351" />
            <circle cx="455.41" cy="1.8351" r="1.8351" />
            <circle cx="487.22" cy="49.548" r="1.8351" />
            <circle cx="487.22" cy="33.644" r="1.8351" />
            <circle cx="487.22" cy="17.739" r="1.8351" />
            <circle cx="487.22" cy="1.8351" r="1.8351" />
            <circle cx="503.12" cy="49.548" r="1.8351" />
            <circle cx="503.12" cy="33.644" r="1.8351" />
            <circle cx="503.12" cy="17.739" r="1.8351" />
            <circle cx="503.12" cy="1.8351" r="1.8351" />
            <circle cx="407.7" cy="17.739" r="1.8351" />
            <circle cx="407.7" cy="1.8351" r="1.8351" />
            <circle cx="391.79" cy="17.739" r="1.8351" />
            <circle cx="391.79" cy="1.8351" r="1.8351" />
            <circle cx="423.6" cy="17.739" r="1.8351" />
            <circle cx="423.6" cy="1.8351" r="1.8351" />
            <circle cx="439.51" cy="17.739" r="1.8351" />
            <circle cx="439.51" cy="1.8351" r="1.8351" />
          </g>
        </symbol>
      </svg>
    </>
  );
}

export default VideoSlider;
