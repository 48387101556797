/*
Галерея с листалкой и превьюшками под ней (на базе Slick.js)
В зависимости от того, какие данные насыпали галерея рендерит и листинги, и шоурум и данные из CMS (параграфы).
*/


import {useState, useEffect, useRef}  from 'react';
import Slider from "@ant-design/react-slick";
import ListingBanner from '../../components/Image/ListingBanner';
//import Banner from '../../components/Image/Banner';
import popupsState from '../../stores/popupsState';
import {API_BASE_URL} from '../../constants/Constants';
import {makeSrcSet} from '../../utils/imageFormat';
import getImageSize from '../../utils/imageFormat';
import './Gallery.scss';
//import {toJS} from 'mobx';


const GalleryArrow = (props) => {
	return (
		<div onClick={props.onClick} className={"gallery_arrow " + (props.prev == undefined ? 'arrow_prev' : 'arrow_next') }>
			<svg width="16" height="16"><use xlinkHref="#i_larr" /></svg>
		</div>
	)
}

const Gallery = (props) => {

	const [state, setState] = useState({nav1: null, nav2: null, init: false});

	const title = props.title ? props.title : null;
	const infinite = props.infinite !== undefined ? props.infinite : false;
	const noIcons = props.noIcons 
	const [videoReady, setVideoReady] = useState(false)

	// var settings = {
	//     dots: props.noIcons,
	//     infinite: false,
	//     speed: 500,
	//     arrows: true,
	//     slidesToShow: props.noIcons ? 3 : 1,
	//     slidesToScroll: 1,
	//     //lazyLoad: 'ondemand',
	//     nextArrow: <GalleryArrow />,
	//     prevArrow: <GalleryArrow prev />
	// };

	if (props.autoplay) {
	  	settings.autoplay = true;
	  	settings.autoplaySpeed = props.autoplay;
	}

  /*var dots = {
	    dots: false,
	    speed: 500,
	    //slidesToShow: 1,
	    centerMode: true,
	    infinite: false,
	    arrows: false,
	    slidesToScroll: 1,
	    variableWidth: true,
	    focusOnSelect: true,
	    nextArrow: <GalleryArrow />,
	    prevArrow: <GalleryArrow prev />
	    //lazyLoad: 'ondemand',
	};*/

	var slider1 = useRef();
	/*var slider2 = useRef();*/
	useEffect(() => {

	  	setState({
	      nav1: slider1,
	      //nav2: slider2,
	      init: true
	    })
  	},[]);


	const getVideoKey = (url) => {
		return url.replace(/https?:\/\/.*(youtube.*v=|youtu.be\/|youtube.com\/embed\/)([^&\n$]*).*/g, '$2');
	}
  
	const videoImgUrl = (url,small = false) => {
	  	const key = getVideoKey(url);
	  	if (key !== '') {
	  		if (small) {
		  		return 'https://img.youtube.com/vi/' + key + '/default.jpg';
		  	} else {
		  		return 'https://img.youtube.com/vi/' + key + '/maxresdefault.jpg';
		  	}
	  	} else {
	  		return '';
	  	}
	 }

  	const popupVideo = (url, e = false) => {
		if (e) e.preventDefault();
	  	const key = getVideoKey(url);
	  	popupsState.popupVideo('youtube_'+key,'Video', key);
  	}

	const popupImage = (url,title, e = false, index) => {
	  	if (e) e.preventDefault();
      
      const currentSlide = e.target.src;
      console.log('popupImage',popupSlides, currentSlide);
	  	popupsState.popupGallery('gallery', title ? title : 'Image', popupSlides, currentSlide, index);
	}

	const switchTab = (e) => {
		setActive(e.target.getAttribute('data-tab'));
	}

	if (props.items == undefined || props.items.length == 0) {
		return null;
	} 

  const popupSlides = [];

	const slides = props.items.map((s, i)=> {
		//console.log('SLIDE', s)
		if (s.mimeType !== undefined) {
			if (s.isImage) {
				const source = s.formatUri.replace('{format}', 'pridea_bigger');

        popupSlides.push(source);
				
				const view = API_BASE_URL + s.formatUri.replace('{format}', 'pridea_view_scale').replace(/(\.png|\.jpg)/, '.webp');
				const view800 = API_BASE_URL + s.formatUri.replace('{format}', 'pridea_view_scale_800').replace(/(\.png|\.jpg)/, '.webp');
				const view400 = API_BASE_URL + s.formatUri.replace('{format}', 'pridea_view_scale_400').replace(/(\.png|\.jpg)/, '.webp');
				const title = s.title? s.title : 'Image ' + i;
				const alt = s.description ? s.description : title;
				const icon = API_BASE_URL + s.formatUri.replace('{format}', 'pridea_icon').replace(/(\.png|\.jpg)/, '.webp');
				const mediaSize = getImageSize(s?.properties?.width, s?.properties?.height, 'pridea_view_scale');
				return (
					<div key={'slide' + i} className="slide">
						<a href={view} onClick={(e) => popupImage(source, title, e, i)}>
							<ListingBanner 
							  imgClassName="banner-image"
								wideBlur={props.wideBlur}
								{...mediaSize} 
								bgSrc={icon} 
								lazy={i > 0} 
								itemProp={props.itemProp} 
								alt={alt} 
								title={title} 
								srcSet={{'400w': view400, '800w': view800, '1176w' : view}} 
								src={view}
							/>
						</a>
					</div>)
			}
			if (s.isVideo) {
				const mediaSize = s.properties && s.properties.width ? s.properties : 
					{width: props.width ? props.width : 1920, height: props.height ? props.height : 676};
          popupSlides.push(s.url);
				return (
					<div key={'slide' + i} className={"slide video_slide" + (videoReady ? ' videoready' : ' videoloading')}>
						<svg {...mediaSize}><use xlinkHref="#img_placeholder" /></svg>
						<video src={s.url} autoPlay muted loop playsInline onPlaying={() => setVideoReady(true)}/>
					</div>)
			}
			//console.log(s,s.mimeType)
		} else {
			if (s.url !== undefined) {
				//mediaSize
        
				return (
					<div key={'slide' + i} className="slide">
						<a href={s.url} onClick={(e) => popupVideo(s.url, e)}>
							<svg className="playButton"><use xlinkHref="#i_plane_video" /></svg>
							<ListingBanner 
								imgClassName="banner-image"
								wideBlur={props.wideBlur}
				//				{...mediaSize} 
				//				bgSrc={icon} 
								lazy={i > 0} 
								itemProp={props.itemProp} 
								src={videoImgUrl(s.url)}
							/>
						</a>
					</div>)
			} else if (s.video !== undefined) {
        popupSlides.push(s.video);
				return (
					<div key={'slide' + i} className="slide">
						<video src={s.video} autoPlay muted loop playsInline />
					</div>)
			} else {
				const mediaSize = getImageSize(s?.properties?.width, s?.properties?.height, 'pridea_view_scale');
        popupSlides.push((props.prefix ? props.prefix : '') + s.view);
				return (
					<div key={'slide' + i} className="slide">
						<a href={s.zoom} onClick={(e) => popupImage(s.zoom, title? title : 'Image '+ i, e, i)}>
							<ListingBanner 
							  imgClassName="banner-image"
								wideBlur={props.wideBlur}
								srcSet={true} 
								{...mediaSize}
								bgSrc={s.icon} 
								lazy={i > 0} 
								itemProp={props.itemProp} 
								src={(props.prefix ? props.prefix : '') + s.view}
							/>
						</a>
					</div>)
			}
		}	
	})

	const getIcon = (i) => {
		if (props.items[i] !== undefined) {
			const s = props.items[i];
			if (s.mimeType !== undefined) {
				if (s.isImage) {
					const icon = API_BASE_URL + s.formatUri.replace('{format}', 'pridea_icon').replace(/(\.png|\.jpg)/, '.webp');
					const title = s.title? s.title : 'Image ' + i;
					const alt = s.description ? s.description : title;
					return <div key={'dot' + i} className="slide-icon"><img src={icon} srcSet={makeSrcSet(true, icon)} title={title} alt={alt} /></div>
				}
				if (s.isVideo) {
					return <div key={'dot' + i} className="slide-icon"><div className="video_icon_placeholder"></div><svg className="playIcon"><use xlinkHref="#i_plane_video" /></svg></div>
				}
			} else {
				if (s.video !== undefined) {
					return <div key={'dot' + i} className="slide-icon"><div className="video_icon_placeholder"></div><svg className="playIcon"><use xlinkHref="#i_plane_video" /></svg></div>
				}
				if (s.url !== undefined) {
					return <div key={'dot' + i} className="slide-icon"><svg className="playIcon"><use xlinkHref="#i_plane_video" /></svg><img src={videoImgUrl(s.url, true)} title={'Slide ' + i} alt={'Slide ' + i} /></div>
				} else {
					const icon = (props.prefix ? props.prefix : '') + s.icon;
					return <div key={'dot' + i} className="slide-icon"><img src={icon} srcSet={makeSrcSet(true, icon)} title={'Slide ' + i} alt={'Slide ' + i} /></div>
				}
			} 
		} 
		return {index};
	}

	const settings = {
	    dots: true,
	    infinite: infinite,
	    speed: 500,
	    arrows: true,
	    slidesToShow: props.noIcons ? 3 : 1,
	    slidesToScroll: 1,
	    //lazyLoad: 'ondemand',
	    nextArrow: <GalleryArrow />,
	    prevArrow: <GalleryArrow prev />,
	    customPaging: getIcon
	};

	if (props.autoplay) {
	  	settings.autoplay = true;
	  	settings.autoplaySpeed = props.autoplay;
	}

	/*const icons = props.noIcons ? false : props.items.map((s, i) => {
		if (s.mimeType !== undefined) {
			if (s.isImage) {
				const icon = API_BASE_URL + s.formatUri.replace('{format}', 'pridea_icon').replace(/(\.png|\.jpg)/, '.webp');
				const title = s.title? s.title : 'Image ' + i;
				const alt = s.description ? s.description : title;
				return <div key={'dot' + i} className="slide"><img src={icon} srcSet={makeSrcSet(true, icon)} title={title} alt={alt} /></div>
			}
			if (s.isVideo) {
				return <div key={'dot' + i} className="slide"><div className="video_icon_placeholder"></div><svg  className="playIcon"><use xlinkHref="#i_video_play" /></svg></div>
			}
		} else {
			if (s.video !== undefined) {
				return <div key={'dot' + i} className="slide"><div className="video_icon_placeholder"></div><svg  className="playIcon"><use xlinkHref="#i_video_play" /></svg></div>
			}
			if (s.url !== undefined) {
				return <div key={'dot' + i} className="slide"><svg  className="playIcon"><use xlinkHref="#i_video_play" /></svg><img src={videoImgUrl(s.url, true)} title={'Slide ' + i} alt={'Slide ' + i} /></div>
			} else {
				const icon = (props.prefix ? props.prefix : '') + s.icon;
				return <div key={'dot' + i} className="slide"><img src={icon} srcSet={makeSrcSet(true, icon)} title={'Slide ' + i} alt={'Slide ' + i} /></div>
			}
		}
	})*/

	return (
		<div className={"gallery " + props.noIcons ? 'gallery_bigone' : 'gallery_icons'}>
	    <Slider className={"gallery_slides " + (state.init ? 'gallery_initialized' : 'gallery_loading')} {...settings} 
          ref={slider => (slider1 = slider)}>
	      {slides}
	    </Slider>
		</div>
	)
}

export default Gallery;
/*
<div className={"gallery " + props.noIcons ? 'gallery_bigone' : 'gallery_icons'}>
	    <Slider className={"gallery_slides " + (state.init ? 'gallery_initialized' : 'gallery_loading')} {...settings} asNavFor={props.noIcons ? null : state.nav2}
          ref={slider => (slider1 = slider)}>
	      {slides}
	    </Slider>
	    {(props.items.length > 1 && !props.noIcons ) &&
		    <Slider className={"gallery_dots " + (state.init ? 'gallery_initialized' : 'gallery_loading')} {...dots} asNavFor={state.nav1}
	          ref={slider => (slider2 = slider)}>
		      {icons}
		    </Slider>
	    }
		</div>

*/
